.contact-page {
  margin-left: 10%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  height: 39rem;
}
#contact-me {
  font-weight: 400;
  padding-left: 40%;
  padding-top: 70px;
  margin-bottom: 60px;
}
.contact-links{
  display: flex;
  justify-content: space-evenly;
}

.contact-icons {
  text-align: center;
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: black;
      font-style: italic;
    }
    h1 {
      font-size: 60px;
      color: white;
      text-decoration: none;
      animation: iconsShrink 0.2s ease-in-out;
      &:hover {
        animation: iconsGrow 0.2s ease-in-out;
        text-shadow:0 0 22px #ffffff;
        font-size: 100px;
        color: #000000; 
      }
    }
  }
}


@keyframes iconsGrow {
  0% {
    font-size: 60px;
  }
  100% {
    font-size: 100px;
  }
}

@keyframes iconsShrink {
  0% {
    font-size: 100px;
  }
  100% {
    font-size: 60px;
  }
}


@media (max-width: 750px) {
  .contact-page{
    margin-left: 0px;
    padding-top: 20%;
    height: 50rem;
  }
  #contact-me{
    padding-left: 0px;
    text-align: center;
  }
  .contact-links{
    margin-top: 20%;
    display: grid;
    grid-template-columns: repeat(2,20vmin);
    grid-template-rows: repeat(2,30vmin)
    
  }
  .contact-icons h3{
    display: none;
  }
}
