.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  --body-bg: linear-gradient(to right bottom, #71a6a8, #4f8491, #336379, #1f435e, #122542);;
  --nav-bg: rgb(112, 112, 112);
  --text-color: rgb(0, 0, 0);
  --light-md: flex;
  --dark-md: none;
  color: var(--text-color);
  background-image: var(--body-bg);
  font-family: 'Luxurious Roman', cursive;
  background-size: 100% 505%;
}

body.dark{
  --body-bg: linear-gradient(to bottom, #0a0d11, #0d1117, #0f141c, #111722, #121a27, #131c2b, #131d2f, #141f33, #142037, #13223a, #13233e, #122542);
  --nav-bg: rgb(47, 46, 46);
  --text-color: rgb(255, 251, 251);
  --light-md: none;
  --dark-md: flex;
}
