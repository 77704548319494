.about-page{
  margin-left: 13%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  height: 39rem;
  h1 {
  font-weight: 400;
  padding-top: 10%;
  padding-left: 12px
  }
  p {
    font-size: large;
    padding-right: 50%;
    padding-left: 12px
  }
}




@media (max-width: 750px) {
  .about-page{
    margin-left: 0px;
    h1 {
      padding-top: 25%;
      text-align: center;
    }
    p {
      font-size: 14px;
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
    }
  }
}

