.landing-page{
  margin-left: 13%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
  height: 39rem;
}

#landing-title{
  padding-top: 15%;
  padding-left: 12px;
  font-weight: 400;
}

.title-role{
  padding-left: 12px;
  display: none;
  border-right: 4px solid var(--text-color) ;
}

.text-container {
  font-family: monospace;
  overflow: hidden;
  white-space: nowrap;
  width: 40rem;
  font-size: 1.5rem;
}

.title-animation{
  display: inline-block;
  animation: typing 2s steps(20) forwards, deleting 2s steps(20) 2.19s forwards;
  white-space: nowrap;
  overflow: hidden;
}

#landing-text{
  
  padding-left: 12px;
  font-style: italic;
  
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes typing {
  from { 
    width: 0 
  }
  to { 
    width: 20rem 
  }
}

@keyframes deleting {
  from { 
    width: 20rem 
  }
  to { 
    width: 0
  }
}


@media (max-width: 750px) {
  .landing-page {
    margin-left: 0;
    padding-top: 10%;
    text-align: center;
  }
  .text-container{
    margin-left: 20px;
    width: 20rem;
  
  }

  #landing-title{
    padding-left: 0rem;
    margin: auto;
  }

  #landing-text {
    padding-left: 1rem;
  }
}