.projects-page{
  margin-left: 10%;
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  h1{
    font-weight: 400;
    padding-left: 40%;
    padding-top: 30px;
  }
}

.projects{
  margin-left: 5%;
  display: grid;
  grid-template-columns: repeat(3, 50vmin);
  grid-gap: 20px;
  img {
    min-height: 155px;
  }
  p {
    font-size: 12px;
  }
}

.card {
  border: solid black 1px;
  margin-left: 10px;
  background-color: lightslategray;
}


.btn{
  margin-left: 20px;
}

@media (max-width: 750px) {
  .projects-page {
    margin-left: 0;
    padding-top: 20%;
    padding-left: 0px;
  }
  .projects {
    margin-left: 15%;
    justify-content: left;
    grid-template-columns: repeat(1, 0vmin);
    text-align: center;
  }
  .card{
    margin:0 auto;
  }
  
}
@media (max-width: 300px) {
  .projects {
    margin-left:-8px;
  }
  .projects-page h1 {
    margin-left: -20px;
  }
  .card {
    border-radius: 0px;
  }
}

@media (min-width: 750px) and (max-width: 1100px){
  .projects {
    margin-left: 50px;
    grid-template-columns: repeat(2,50vmin);
  }
}



