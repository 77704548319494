.certifications{
  margin-left: 10%;
  opacity: 1;
  height: 39rem;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
  .title{
    font-weight: 400;
    padding-left: 40%;
    padding-top: 50px;
  }
  .certificates{
    margin-top: 30px;
    margin-left: 30%;
    display: grid;
    grid-template-columns: repeat(1, 50vmin);
    grid-gap: 20px;
    .certs{
      width: 400px;
      max-height: 280px; 
    }
  }
}


@media (max-width: 750px) {
  .certifications{
    padding-top: 35px;
    margin-right: 20%;
    .title{
      padding-left: 35%;
    }
    .certificates{
      margin-left: 10%;
      .certs{
        width: 300px;
        max-height: 200px; 
      }
    }
  }
}

@media (max-width: 300px) {
  .certifications{
    padding-top: 35px;
    margin-right: 40%;
    .certificates{
      // margin-left: 10%;
      .certs{
        width: 220px;
        max-height: 130px; 
      }
    }
  }
}