ul {
  list-style: none;
}



#nav {
  z-index: 999;
  list-style-type: none;
  margin: -22px 0 0 0;
  padding: 0;
  width:100px;
  min-width: fit-content;
  background-color: var(--nav-bg);
  height: 110%; 
  position: fixed;
  overflow: auto;
  border: solid #000000 2px;
  overflow-y: hidden;
}


.nav:hover{
  width: 200px;
  animation: navExpand 0.5s both;
}

.nav:not(:hover){
  animation: navShrink 0.5s both;
}

.tag{
  display: flex;
  position: absolute;
  padding-left: 70px;
  margin-top: -31px;
}

.li-item{
  display: flex;
}


/* #nav ::after {

} */


li {
  margin-top: 50px;
}

.nav-item {
  color:white;
  padding-right: 10px;
  font-size: 10px;
  text-decoration: none;
}

#toggle-btn {
  padding:0;
  color: rgb(255, 255, 255);
  background-color: #90909000;
  border: 0px;
}
#toggle-btn:hover {
  color: rgb(0, 0, 0)
}
#sun{
  display: var(--light-md);
}
#moon{
  display: var(--dark-md);
}

.nav-item:hover {
  color:rgb(0, 0, 0)
}
.nav-item:focus {
  color: rgb(0, 0, 0);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #909090;
}
::-webkit-scrollbar-thumb {
  background: #000000;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes navExpand {
  0% {
    width: 100px;
  }
  100% {
    width: 240px;
  }
}

@keyframes navShrink {
  0% {
    width: 200px;
  }
  100% {
    width: 100px;
  }
}

@keyframes navEnter{
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
  }
  100%{
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes navEnterMobile {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@media only screen and (max-width: 750px) {
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-between;
    list-style-type: none; 
  }
  
  #nav {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: flex-start;
    height: fit-content;
    width: 100%;
    z-index: 1;
    padding-bottom: 3px;
    animation: navEnterMobile 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  
  .list-item {
    display: flex;
    justify-content: space-between;
  }
  
  .tag {
    display: none;
    position: none;
  }
  
}
