@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes iconsGrow {
  0% {
    font-size: 60px;
  }
  100% {
    font-size: 100px;
  }
}

@mixin icons-grow {
  animation: iconsGrow 1s ease-in-out both;
}

.icons-grow {
  @include icons-grow();
}